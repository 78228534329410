import React, { FC, useCallback, useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { Layout, FeedSection, EventSection, TodaySection, Loader } from '../../components';
import {
  default as SectionListWeb,
  default as SectionListNative,
} from '../../components/SectionList/index';
import { NavigationParamList, ScreenName, TabNavigationScreenProps } from '../../navigation/types';
import { useModules } from '../../contexts/Module/ModuleContext';
import { ModuleKey, Role } from '../../client/interfaces';
import { isNative } from '../../utils/responsive';

const modulesToComponents: { [key in ModuleKey]: FC } = {
  [ModuleKey.feed]: FeedSection,
  [ModuleKey.today]: TodaySection,
  [ModuleKey.event]: EventSection,
  [ModuleKey.canteen]: null,
  [ModuleKey.sport]: null,
};

const modulesToRoles: { [key in ModuleKey]: Role | null } = {
  [ModuleKey.feed]: Role.publisher,
  [ModuleKey.today]: null,
  [ModuleKey.event]: Role.publisher,
  [ModuleKey.canteen]: null,
  [ModuleKey.sport]: null,
};

const HomeScreen: React.FC<
  TabNavigationScreenProps<NavigationParamList, ScreenName.HomeScreen>
> = ({ navigation }) => {
  const { modules, setModules } = useModules();
  const [isLoading, setIsLoading] = useState(true);

  const sectionListCommonProps = {
    sections: modules,
    setter: setModules,
    componentMap: modulesToComponents,
  };

  // TODO: csúnya megoldás a navigálás utáni scrollToTop-ra, illetve, hogy működjön az azonos oldalra navigáláskor is. screenListeners-el meg lehet oldani web-re, natívra még nem sikerült jó megoldást találni
  useEffect(() => {
    return navigation.addListener('state', (a) => {
      setIsLoading(true);
      if (navigation.isFocused()) {
        setTimeout(() => setIsLoading(false), isNative ? 100 : 300);
      }
    });
  }, [navigation]);

  return (
    <Layout isScrollView={!isNative} isHomeScreen={true}>
      {isLoading ? (
        <View className="flex-1 items-center justify-center">
          <Loader />
        </View>
      ) : Platform.OS === 'web' ? (
        <SectionListWeb {...sectionListCommonProps} roleMap={modulesToRoles} />
      ) : (
        <SectionListNative {...sectionListCommonProps} roleMap={modulesToRoles} />
      )}
    </Layout>
  );
};
export default HomeScreen;

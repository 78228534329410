import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import {
  GetAdminArticlesParams,
  GetAdminEventsParams,
  GetAdminExtremeEventsParams,
  GetArticlesParams,
  GetEventsParams,
  ModuleKey,
} from '../client/interfaces';
import { ErrorType } from '../resources/interfaces';

export type NavigationParamList = {
  [ScreenName.HomeScreen]: undefined;
  [ScreenName.FavoritesScreen]: undefined;
  [ScreenName.PlatformsScreen]: undefined;
  [ScreenName.SettingsScreen]: undefined;
  [ScreenName.ArticlesScreen]: GetArticlesParams;
  [ScreenName.EventsScreen]: GetEventsParams;
  [ScreenName.ProfileScreen]: undefined;
  [ScreenName.ArticleScreen]: { postId: number };
  [ScreenName.EventScreen]: { postId: number };
  [ScreenName.OnboardingScreen]: undefined;
  [ScreenName.AdminIndexScreen]: undefined;
  [ScreenName.AdminArticleListScreen]: GetAdminArticlesParams;
  [ScreenName.AdminArticleEditScreen]: { postId?: number };
  [ScreenName.AdminExtremeEventListScreen]: GetAdminExtremeEventsParams;
  [ScreenName.AdminEventListScreen]: GetAdminEventsParams;
  [ScreenName.AdminExtremeEventEditScreen]: { postId?: number };
  [ScreenName.AdminEventEditScreen]: { postId?: number };
  [ScreenName.ErrorScreen]: { type?: ErrorType };
  '*': { type?: ErrorType };
};

export enum Navigator {
  main = 'main',
  profile = 'profile',
}

export interface Screen {
  name: ScreenName;
  route: string;
  translation: string;
  navigator?: Navigator;
  icon?: any;
  moduleKey?: ModuleKey; // Ki-be kapcsolható backenden
}

//TODO: Maybe notification and profileSub has to be changed to use it differently

export enum ScreenName {
  HomeScreen = `${Navigator.main}/HomeScreen`,
  EventsScreen = `${Navigator.main}/EventsScreen`,
  FavoritesScreen = `${Navigator.profile}/FavoritesScreen`,
  PlatformsScreen = `${Navigator.main}/PlatformsScreen`,
  ArticlesScreen = `${Navigator.main}/ArticlesScreen`,
  AdminIndexScreen = `${Navigator.profile}/AdminIndexScreen`,
  AdminArticleListScreen = `AdminArticleListScreen`,
  AdminArticleEditScreen = `AdminArticleEditScreen`,
  AdminExtremeEventListScreen = `AdminExtremeEventListScreen`,
  AdminExtremeEventEditScreen = `AdminExtremeEventEditScreen`,
  AdminEventListScreen = `AdminEventListScreen`,
  AdminEventEditScreen = `AdminEventEditScreen`,
  SettingsScreen = `${Navigator.profile}/SettingsScreen`,
  ProfileScreen = `/profile`,
  OnboardingScreen = `/onboarding`,
  ArticleScreen = `/article`,
  EventScreen = `/event`,
  ErrorScreen = `/error`,
  NotFound = `*`,
}

export type NotificationRedirectScreenName = Extract<
  ScreenName,
  ScreenName.ArticleScreen | ScreenName.EventScreen | ScreenName.HomeScreen
>;

export type TabNavigationScreenProps<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList = keyof ParamList,
  NavigatorID extends string | undefined = undefined,
> = {
  navigation: NavigationProp<ParamList, RouteName, NavigatorID>;
  route: RouteProp<ParamList, RouteName>;
};

export type TabNavigationOptions = {
  title?: string;
};

export type TabNavigationEventMap = {
  tabPress: {
    data: { isAlreadyFocused: boolean };
    canPreventDefault: true;
  };
};
